import React, { useCallback } from 'react';
import { Page, Card, DropZone } from '@shopify/polaris';
import { useParams } from 'react-router-dom';

function AdminLabels() {
  const { shopOrigin, hmac } = useParams();

  const handleDrop = useCallback(async (_files, acceptedFiles: File[], _rejectedFiles) => {
    for (let f of acceptedFiles) {
      if (f.type !== "text/csv") {
        alert(`Error: ${f.name} is not a CSV`)
        return
      }

      let result = await fetch(`/api/signed/${shopOrigin}/${hmac}/admin/printLabelsFromCSV`, {
        method: "POST",
        body: await f.text()
      })

      if (!result.ok) {
        alert("Error: " + await result.text())
        return
      }

      let iframe = document.createElement("iframe");
      iframe.srcdoc = await result.text()
      document.body.appendChild(iframe)
    }
  }, [shopOrigin, hmac]);

  return (
    <Page
      title="Generate shipping labels"
      fullWidth
    >
      <Card>
        <DropZone onDrop={handleDrop}>
          <DropZone.FileUpload />
        </DropZone>
      </Card>
    </Page>
  )
}

export default AdminLabels;