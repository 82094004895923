import React from 'react';
import { Page, Layout, Card, TextContainer, TextStyle, Link } from '@shopify/polaris'

export default function GettingStarted() {
  return (
    <Page title="Getting Started">
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section title="Swyft Delivery Zone">
              <p>Toronto</p>
            </Card.Section>
          </Card>
          <Card>
            <Card.Section title="Step 1">
              <p>Open the <Link url="/carrier">settings</Link> page.</p>
            </Card.Section>

            <Card.Section title="Step 2">
              <p>Set your pickup location.</p>
            </Card.Section>

            <Card.Section title="Step 3">
              <TextContainer spacing="loose">
                <p>Enter the rate to display to customers on checkout.</p>
                <TextStyle variation="subdued">
                  <p><i>Swyft will charge you a Service charge per delivery as agreed upon in your agreement.</i></p>
                </TextStyle>
              </TextContainer>
            </Card.Section>

            <Card.Section title="Step 4">
              <p>Click save and then enable dynamically calculated shipping rates.</p>
            </Card.Section>

            <Card.Section title="Step 5">
              <p>Swyft is now set up and serving rates on checkout!</p>
            </Card.Section>
          </Card>
          <Card>
            <Card.Section title="Fulfill Orders">
              <p>As new Swyft enabled orders come in you can see them in the Orders tab and print shipping labels.</p>
              <p>Our drivers will arrive at 5 p.m. every day to pick up orders. Happy shipping!</p>
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}