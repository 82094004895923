import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Frame, TopBar, Loading } from '@shopify/polaris';

import theme from './theme';
import Navigation from './Navigation';
import LoadingPage from './LoadingPage';
import AccountDetails, { IContract } from './AccountDetails';
import GettingStarted from './GettingStarted';
import Carrier from './Carrier';
import Link from './Link';
import Orders from './Orders';
import AdminOrders from './AdminOrders';
import AdminLabels from './AdminLabels';


function App() {
  const [isLoading, setIsLoading] = useState(window.location.pathname.match(/^\/admin/) === null);
  const [shopOrigin, setShopOrigin] = useState("");
  const [contract, setConract] = useState<IContract | undefined>(undefined)

  const fetchContract = async () => {
    if (window.location.pathname.match(/^\/admin/) !== null) {
      return
    }

    const result = await fetch("/api/v1/contract");
    setIsLoading(false);

    // If contract not found then we will display getting started info
    if (result.status === 404) {
      return;
    }

    if (!result.ok) {
      return;
    }

    const data = await result.json()
    setShopOrigin(data.shopOrigin);
    setConract(data);
  };

  useEffect(() => {
    fetchContract();
  }, [])

  return (
    <AppProvider i18n={enTranslations} theme={theme} linkComponent={Link}>
      <Router>
        <Frame
          topBar={(<TopBar />)}
          navigation={(<Navigation shopOrigin={shopOrigin} />)}
        >
          {isLoading && <Loading />}
          <Switch>
            <Route exact path="/carrier">
              <Carrier contract={contract} refreshContract={fetchContract} />
            </Route>
            <Route exact path="/orders">
              <Orders shopOrigin={shopOrigin} />
            </Route>
            <Route exact path="/admin/orders/:shopOrigin/:hmac">
              <AdminOrders />
            </Route>
            <Route exact path="/admin/labels/:shopOrigin/:hmac">
              <AdminLabels />
            </Route>
            <Route path="/">
              {isLoading && <LoadingPage />}
              {!isLoading && !contract && <GettingStarted />}
              {!isLoading && contract && <AccountDetails contract={contract} />}
            </Route>
          </Switch>
        </Frame>
      </Router>
    </AppProvider>
  )
}

export default App;