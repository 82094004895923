import React, { useState, useEffect, ReactNode} from 'react';
import { Page, Badge, Card, DataTable, PageActions } from '@shopify/polaris';
import LoadingPage from './LoadingPage';

export interface IShippingLine {
  title: string,
  code: string,
  phone: string,
}

export interface IAddress {
  first_name: string,
  last_name: string,
  address1: string,
  address2: string,
  city: string,
  zip: string,
  province: string,
  country: string,
}

export interface IOrder {
  id: number,
  order_number: number,
  created_at: string,
  fulfillment_status: string,
  shipping_lines: IShippingLine[],
  shipping_address: IAddress,
}

export interface IGetOrdersResponse {
  orders: IOrder[],
  next: string
}

export function formatDate(str: string): string {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]

  const d = new Date(str)
  return `${monthNames[d.getMonth()]} ${d.getDate()} ${d.getHours()}:${d.getMinutes()}`
}

function Orders({shopOrigin} : {shopOrigin: string}) {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([] as ReactNode[][]);
  const [next, setNext] = useState("");
  const [cursor, setCursor] = useState("");
  const [loadedOrders, setLoadedOrders] = useState(new Set())
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    const fetchOrders = async () => {
      let url = '/api/v1/getOrders';

      if (cursor !== "") url = `${url}?cursor=${cursor}`;

      if (!loadedOrders.has(url)) {
        setLoadedOrders(loadedOrders.add(url));

        const result = await fetch(url);

        if (!result.ok) {
          return;
        }

        const data = (await result.json()) as IGetOrdersResponse;
        setNext(data.next);

        for (const order of data.orders) {
          rows.push([
            <a className="Polaris-Link" href={"https://" + shopOrigin + "/admin/orders/" + order.id} target="_blank"
               rel="noopener noreferrer">#{order.order_number}</a>,
            order.shipping_address.first_name + " " + order.shipping_address.last_name,
            formatDate(order.created_at),
            order.shipping_address.city,
            order.fulfillment_status ?
                <Badge status="success">Fulfilled</Badge> :
                <Badge progress="incomplete">Unfulfilled</Badge>,
            <a className="Polaris-Link" href={"/api/v1/printLabel/" + order.id} target="_blank"
               rel="noopener noreferrer">Print Label</a>
          ]);
        }

        setRows([...rows]);
      }

      setIsLoading(false);
      setButtonLoading(false);
    }

    setButtonLoading(true);
    fetchOrders();
  }, [shopOrigin, cursor, rows, loadedOrders]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Page
      title="Orders"
      primaryAction={{
        content: 'Print Unfulfilled',
        onAction: () => { window.open("/api/v1/printUnfulfilled") },
      }}
    >
      <Card>
        <DataTable
          headings={[
            'Order',
            'Customer Name',
            'Created',
            'City',
            'Fulfilled',
            'Action'
          ]}
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
            'text',
            'text',
          ]}
          rows={rows}
        />
      </Card>
      <PageActions primaryAction={{
        content: 'Load More',
        disabled: next === "",
        loading: buttonLoading,
        onAction() {setCursor(next);}
      }}/>
    </Page>
  );
}

export default Orders;