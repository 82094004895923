import React, { useState, useEffect } from 'react';
import { Page, Layout, SettingToggle, Button, Collapsible, TextStyle, Card, Form, FormLayout, TextField, Toast, ButtonGroup, Stack } from '@shopify/polaris';
import LoadingPage from './LoadingPage';
import { IContract } from './AccountDetails';

interface ICarrier {
  enabled?: boolean,
  carrier_service?: {
    name?: string,
  },
}

function Carrier({ contract, refreshContract }: { contract: IContract | undefined, refreshContract: () => Promise<void> }) {
  const [isLoading, setIsLoading] = useState(true);
  const [carrier, setCarrier] = useState<ICarrier>({});
  const [toast, setToast] = useState("");
  const [errorToast, setErrorToast] = useState("");

  // One for each location form field
  const [firstName, setFirstName] = useState(contract?.pickupLocation.first_name)
  const [address1, setAddress1] = useState(contract?.pickupLocation.address1)
  const [address2, setAddress2] = useState(contract?.pickupLocation.address2)
  const [city, setCity] = useState(contract?.pickupLocation.city)
  const [zip, setZip] = useState(contract?.pickupLocation.zip)

  // One for each rate form field
  const [price, setPrice] = useState(contract?.price)

  // One for each free shipping form field
  const [freeShippingFlag, setFreeShippingFlag] = useState(contract?.freeShippingFlag)
  const [freeShippingPrice, setFreeShippingPrice] = useState(contract?.freeShippingPrice)

  // Shipstation
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [tag, setTag] = useState('')
  const [webhookId, setWebhookId] = useState('')

  const getShipstationConfig = async () => {
    const result = await fetch("/shipstation/config", {
      headers: {
        'X-Swyft-Access-Token': contract?.apiToken || '',
      }
    });
    if (!result.ok) {return;}

    const {config} = await result.json();

    setApiKey(config.apiKey || '')
    setApiSecret(config.apiSecret || '')
    setTag(config.tagId || '')
    setWebhookId(config.webhookId || '')
  }

  const tagSearch = async () => {
    const result = await fetch("/shipstation/syncTags", {
      method: 'POST',
      headers: {
        'X-Swyft-Access-Token': contract?.apiToken || '',
      }
    });
    if (!result.ok) {
      const {error} = await result.json();
      setErrorToast(error)
      return;
    }

    return getShipstationConfig();
  }

  const updateShipstationConfig = async () => {
    setIsLoading(true)
    const result = await fetch("/shipstation/config", {
      method: 'POST',
      body: JSON.stringify({
        apiKey,
        apiSecret,
      }),
      headers: {
        'X-Swyft-Access-Token': contract?.apiToken || '',
        'Content-Type': 'application/json'
      }
    });
    if (!result.ok) {
      const {error} = await result.json();
      setErrorToast(error)
      return;
    }

    const webhookResult = await fetch("/shipstation/webhooks/subscribe", {
      method: 'POST',
      body: JSON.stringify({
        apiKey,
        apiSecret,
      }),
      headers: {
        'X-Swyft-Access-Token': contract?.apiToken || '',
        'Content-Type': 'application/json'
      }
    });
    if (!webhookResult.ok) {
      const {error} = await webhookResult.json();
      setErrorToast(error)
      return;
    }

    setToast("Shipstation config updated!")
    setIsLoading(false);

    return getShipstationConfig()
  }

  const getCarrier = async () => {
    const result = await fetch("/api/v1/getCarrier");
    if (!result.ok) { return; }

    await getShipstationConfig();

    setIsLoading(false);
    setCarrier(await result.json());
  };

  const createCarrier = async () => {
    const result = await fetch("/api/v1/createCarrier");
    if (!result.ok) {
      setErrorToast(await result.text())
      return;
    }

    setToast("Carrier enabled")
    getCarrier();
  }

  const deleteCarrier = async () => {
    const result = await fetch("/api/v1/deleteCarrier", {
      method: "POST"
    })
    if (!result.ok) {
      setErrorToast(await result.text())
      return;
    }

    setToast("Carrier Disabled");
    setCarrier({});
  }

  const performUpdate = async() => {
    updateLocationAndRate()
    setToast("Settings updated");
  }

  const updateFreeShippingRate = async () => {
    updateLocationAndRate()
    setToast("Updated Free Shipping for Customers");
  }

  const updateLocationAndRate = async () => {
    const result = await fetch("/api/v1/updateLocationAndRate", {
      method: "POST",
      body: JSON.stringify({
        first_name: firstName,
        address1,
        address2,
        city,
        zip,
        price,
        freeShippingPrice,
        freeShippingFlag
      }),
    })

    if (!result.ok) {
      setErrorToast(await result.text());
      return;
    }

    refreshContract();
  }

  function disableFreeShippingRate() {
    return (<Collapsible
      open={true}
      id="disabled-collapsible"
      transition={{ duration: '150ms', timingFunction: 'ease' }}
    >
      <p> Free shipping for customers based on their cart amount is currently { contract?.freeShippingFlag ? <TextStyle variation="positive">enabled</TextStyle> : <TextStyle variation="negative">disabled</TextStyle>  } </p>
    </Collapsible>)
  }

  function enableFreeShippingRate() {

    return (

      <Collapsible
        open={true}
        id="enabled-collapsible"
        transition={{ duration: '150ms', timingFunction: 'ease' }}
      >
        <p> Free shipping with a cart amount of {freeShippingPrice} cents or greater, is currently { contract?.freeShippingFlag ? <TextStyle variation="positive">enabled</TextStyle> : <TextStyle variation="negative">disabled</TextStyle>  } </p>

        <br />

        <Form implicitSubmit={false} onSubmit={updateLocationAndRate}>
          <FormLayout>
            <FormLayout.Group>
              <TextField label="Currency" placeholder="Currency" value="CAD" disabled />
              <TextField label="Price (in cents)" type="number" value={freeShippingPrice} onChange={setFreeShippingPrice} />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Collapsible>
    )
  }

  useEffect(() => {
    getCarrier();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    setFreeShippingFlag(contract?.freeShippingFlag)
    setFreeShippingPrice(contract?.freeShippingPrice)
  }, [contract])

  useEffect(() => {
    setFirstName(contract?.pickupLocation.first_name);
    setAddress1(contract?.pickupLocation.address1);
    setAddress2(contract?.pickupLocation.address2);
    setCity(contract?.pickupLocation.city);
    setZip(contract?.pickupLocation.zip);
    setPrice(contract?.price);
  }, [contract])

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card>
            {carrier.enabled &&
              <SettingToggle
                action={{
                  content: 'Disable',
                  onAction: deleteCarrier,
                }}
                enabled={true}
              >
                Dynamically calculated shipping rates on <TextStyle variation="strong">checkout</TextStyle> are <TextStyle variation="positive">enabled</TextStyle>.
            </SettingToggle>
            }
            {!carrier.enabled &&
              <SettingToggle
                action={{
                  content: 'Enable',
                  onAction: createCarrier,
                }}
                enabled={false}
              >
                Dynamically calculated shipping rates on <TextStyle variation="strong">checkout</TextStyle> are <TextStyle variation="negative">disabled</TextStyle>.
            </SettingToggle>
            }
          </Card>

          <Card title="Pickup Location">
            <Card.Section>
              {/* todo: need form or just textfield? */}
              <Form implicitSubmit={false} onSubmit={updateLocationAndRate}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField label="Business Name" labelHidden placeholder="Business name (also used when notifying customers by SMS)" value={firstName} onChange={setFirstName} />
                  </FormLayout.Group>
                  <TextField label="Address" labelHidden placeholder="Address" value={address1} onChange={setAddress1} />
                  <TextField label="Address2" labelHidden placeholder="Apartment, suite, etc. (optional)" value={address2} onChange={setAddress2} />
                  <TextField label="City" labelHidden placeholder="City" value={city} onChange={setCity} />
                  <FormLayout.Group>
                    <TextField label="Country" placeholder="Country" value="Canada" disabled />
                    <TextField label="Province" placeholder="Ontario" value="Ontario" disabled />
                    <TextField label="Postal Code" placeholder="M5S" value={zip} onChange={setZip} />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card.Section>
          </Card>

          <Card
            title="Displayed Rate"
            primaryFooterAction={{
              content: "Update Location and Rate",
              onAction: performUpdate,
            }}
          >
            <Card.Section>
              <Form implicitSubmit={false} onSubmit={updateLocationAndRate}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField label="Currency" placeholder="Currency" value="CAD" disabled />
                    <TextField label="Price (in cents)" placeholder="1000" type="number" value={price} onChange={setPrice} />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card.Section>
          </Card>

          <Card title="Free Shipping for Customers" sectioned primaryFooterAction={{
            content: "Update Free Shipping",
            onAction: updateFreeShippingRate,
          }}>
            <Layout>
              <Layout.Section>
                <Stack distribution="fill">
                  {freeShippingFlag ? enableFreeShippingRate() : disableFreeShippingRate()}
                </Stack>
              </Layout.Section>

              <Layout.Section secondary>
                <Stack distribution="trailing" vertical={true} alignment="trailing">
                  <Stack.Item>
                    <ButtonGroup segmented>
                      <Button pressed={freeShippingFlag} onClick={() => setFreeShippingFlag(true)} ariaExpanded={true} ariaControls="enabled-collapsible">
                        Enable
                      </Button>
                      <Button pressed={!freeShippingFlag} onClick={() => setFreeShippingFlag(false)} ariaExpanded={true} ariaControls="disabled-collapsible">
                        Disable
                      </Button>
                    </ButtonGroup>
                  </Stack.Item>

                </Stack>
              </Layout.Section>
            </Layout>
          </Card>

          {contract?.shopOrigin === 'swyft-dev.myshopify.com' || contract?.shopOrigin === 'dermalogica-ca-staging.myshopify.com' || contract?.shopOrigin === 'dermalogica-ca.myshopify.com' ?
            <Card
              title="Shipstation Integration"
              primaryFooterAction={{
                content: "Sync Shipstation Config",
                onAction: updateShipstationConfig,
              }}
            >
              <Card.Section>
                <Form implicitSubmit={false} onSubmit={updateShipstationConfig}>
                  <FormLayout>
                    <FormLayout.Group>
                      <TextField label="API Key" value={apiKey} onChange={setApiKey}/>
                      <TextField label="API Secret" value={apiSecret} onChange={setApiSecret}/>
                    </FormLayout.Group>
                  </FormLayout>
                </Form>

                {webhookId && webhookId !== '' &&
                  <>
                {tag && tag !== '' ?
                  <SettingToggle
                    action={{
                      content: 'Swyft Tag Sync Active',
                      onAction: () => {
                      },
                    }}
                    enabled={true}
                  >
                    Auto-tagging orders as Swyft is <TextStyle variation="positive">enabled</TextStyle>.
                  </SettingToggle>
                  :
                  <SettingToggle
                    action={{
                      content: 'Enable Tag Sync',
                      onAction: tagSearch,
                    }}
                    enabled={false}
                  >
                    Auto-tagging orders as Swyft is <TextStyle variation="negative">disabled</TextStyle>.
                  </SettingToggle>
                }
                </>
                }
              </Card.Section>
            </Card>
          : null}

          {toast && <Toast content={toast} onDismiss={() => { setToast("") }} />}
          {errorToast && <Toast content={errorToast} error onDismiss={() => { setErrorToast("") }} />}
        </Layout.Section>
      </Layout>
    </Page >
  )
}

export default Carrier;
