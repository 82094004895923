import React from 'react';
import { Navigation } from '@shopify/polaris'
import { ArrowLeftMinor, HomeMajorMonotone, ShipmentMajorMonotone, OrdersMajorMonotone } from "@shopify/polaris-icons"

function NavigationMarkup({ shopOrigin }: { shopOrigin?: string }) {
  return (
    <Navigation location="/">
      <Navigation.Section items={[
        {
          url: shopOrigin ? "https://" + shopOrigin + "/admin" : 'https://shopify.com/admin',
          label: 'Back to Shopify',
          icon: ArrowLeftMinor,
        },
      ]} />
      <Navigation.Section separator items={[
        {
          url: "/home",
          label: 'Home',
          icon: HomeMajorMonotone,
        },
        {
          url: "/orders",
          label: 'Orders',
          icon: OrdersMajorMonotone,
        },
        {
          url: "/carrier",
          label: 'Settings',
          icon: ShipmentMajorMonotone,
        }
      ]} />
    </Navigation>
  )
}

export default NavigationMarkup;