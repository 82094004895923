import React, {useState, useEffect, ReactNode} from 'react';
import {Badge, Page, Card, DataTable, TextField, PageActions} from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import LoadingPage from './LoadingPage';
import {IGetOrdersResponse, formatDate} from './Orders';

function AdminOrders() {
  const { shopOrigin, hmac } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([] as ReactNode[][]);
  const [next, setNext] = useState("");
  const [cursor, setCursor] = useState("");
  const [loadedOrders, setLoadedOrders] = useState(new Set())
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    const fetchOrders = async () => {
      let url = `/api/signed/${shopOrigin}/${hmac}/admin/getOrders`

      if (cursor !== "") url = `${url}?cursor=${cursor}`;

      if (!loadedOrders.has(url)) {
        setLoadedOrders(loadedOrders.add(url));

        const result = await fetch(url)
        if (!result.ok) {
          return;
        }

        const data = (await result.json()) as IGetOrdersResponse;
        setNext(data.next);

        for (const order of data.orders) {
          const address = order.shipping_address;

          let printedAddress = address.address1 + '\n';
          if (address.address2) {
            printedAddress += address.address2 + '\n';
          }
          printedAddress += address.city + ', ' + address.province + '\n';
          printedAddress += address.zip + '\n';

          rows.push([
            '#' + order.order_number,
            formatDate(order.created_at),
            order.fulfillment_status ?
                <Badge status="success">Fulfilled</Badge> :
                <Badge progress="incomplete">Unfulfilled</Badge>,
            <TextField label="" value={`${address.first_name} ${address.last_name}`} readOnly/>,
            <TextField label="" value={printedAddress} multiline readOnly/>,
            <TextField label="" value={order.shipping_lines[0].phone} readOnly/>,
          ])
        }

        setRows([...rows]);
      }

      setIsLoading(false);
      setButtonLoading(false);
    };

    setButtonLoading(true);
    fetchOrders();
  }, [shopOrigin, cursor, hmac, rows, loadedOrders]);

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <Page
      title={"Orders for " + shopOrigin}
      primaryAction={{
        content: "Export CSV",
        onAction: () => { window.open(`/api/signed/${shopOrigin}/${hmac}/admin/exportCSV`) },
      }}
      secondaryActions={[{
        content: "New Export CSV",
        onAction: () => { window.open(`/api/signed/${shopOrigin}/${hmac}/admin/datastoreExportCSV`) },
      }]}
      fullWidth
    >
      <Card>
        <DataTable
          headings={[
            'Order',
            'Created',
            'Fulfilled',
            'Name',
            'Address',
            'Phone',
          ]}
          columnContentTypes={[
            'text',
            'text',
            'text',
            'text',
            'text',
            'text',
          ]}
          rows={rows}
        />
      </Card>
      <PageActions primaryAction={{
        content: 'Load More',
        disabled: next === "",
        loading: buttonLoading,
        onAction() {setCursor(next);}
      }}/>
    </Page>
  )
}

export default AdminOrders;
