import React from 'react';
import {Page,Layout,Card} from '@shopify/polaris'
import { IAddress } from './Orders';

export interface IContract {
  shopOrigin: string,
  description: string,
  currency: string,
  price: string,
  location: string,
  pickupLocation: IAddress,
  freeShippingPrice: string,
  freeShippingFlag: boolean,
  apiToken: string,
}

export default function AccountDetails({contract}: {contract: IContract | undefined}) {
  const pickupLocation = contract?.pickupLocation;
  return (
    <Page title="Account Details">
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <Card.Section title="Shopify Store">
            <p>{contract?.shopOrigin}</p>
          </Card.Section>

          <Card.Section title="Delivery Zone">
            <p>{contract?.location}</p>
          </Card.Section>

          <Card.Section title="Pickup Location">
  <p>{pickupLocation?.first_name} {pickupLocation?.last_name}, {pickupLocation?.address1}, {pickupLocation?.address2}, {pickupLocation?.city} {pickupLocation?.province}, {pickupLocation?.zip}</p>
          </Card.Section>

          <Card.Section title="Delivery SLA">
            <p>Orders placed by 2:00pm EST will be delivered same day by 10pm EST</p>
          </Card.Section>

          <Card.Section title="Currency">
            <p>{contract?.currency}</p>
          </Card.Section>

          <Card.Section title="Price">
            <p>${contract?.price && parseInt(contract.price) / 100}</p>
          </Card.Section>

          <Card.Section title="Free Shipping Toggle">
            <p>{contract?.freeShippingFlag ?'Enabled' : 'Disabled'}</p>
          </Card.Section>

          <Card.Section title="Free Shipping for Carts Over">
            <p>${contract?.freeShippingPrice && parseInt(contract.freeShippingPrice) / 100}</p>
          </Card.Section>

        </Card>
      </Layout.Section>
    </Layout>
  </Page>
  )
}
