const theme = {
  colors: {
    topBar: {
      background: '#5c7de9',
    },
  },
  logo: {
    topBarSource: 'https://uploads-ssl.webflow.com/5eacf21aceae40cac6f96554/5eacf3fdb8d39cd1d46ae721_White%20logo%20-%20no%20background.svg',
    url: 'https://elmo.shipswyft.com',
  }
}

export default theme;