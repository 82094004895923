import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

function Link({url, children, ...rest}: {url: string, children?:React.ReactNode}) {
  if (url.startsWith('https://')) {
    return <a href={url} {...rest}>{children}</a>
  }

  return <RouterLink to={url} {...rest}>{children}</RouterLink>
}

export default Link;